import Swiper from 'swiper/bundle';
require('bootstrap/js/dist/carousel');

const swiperLegal = new Swiper('.swiperLegal', {
    slidesPerView: "auto",
    spaceBetween: 30,
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
        hide: false,
    },
    breakpoints: {
        200: {
            slidesPerView: 1,
            spaceBetween: 10
          },
        768: {
          slidesPerView: 'auto',
          spaceBetween: 30
        }
    }
});

const swiperSliderFull = new Swiper('.sliderFullItems', {
    speed: 1000,
    parallax: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
});

const swiperLastArticles = new Swiper('.swiperLastArticles', {
    slidesPerView: 4,
    spaceBetween: 30,
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
        hide: false,
    },
    navigation: {
        nextEl: '.swiper-button-next.articles',
        prevEl: '.swiper-button-prev.articles',
    },
    breakpoints: {
        200: {
            slidesPerView: 1,
            spaceBetween: 10
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 30
        }
    }
});

const swiperBestBrandsCard = new Swiper('.swiperBestBrandsCard', {
    slidesPerView: 4,
    spaceBetween: 30,
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
        hide: false,
    },
    navigation: {
        nextEl: '.swiper-button-next.brands',
        prevEl: '.swiper-button-prev.brands',
    },
    breakpoints: {
        200: {
            slidesPerView: 1,
            spaceBetween: 10
          },
        768: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 30
        }
    }
});